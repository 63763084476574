<template>
  <div class="rs-admin-edit">
    <v-banner
      app
      sticky
      elevation="8"
      color="white"
      style="z-index: 6"
      class="mx-n8 mt-n4"
    >
      <v-row>
        <v-col>
          <v-btn text to="/admin/organizations"
            ><v-icon class="mr-4">mdi-arrow-left</v-icon> Organizations</v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end">
          <v-btn to="/admin/organizations" text color="error" class="mr-4">
            Cancel
          </v-btn>

          <v-btn :disabled="!valid" @click="handleSave" color="primary">
            {{ mode === "new" ? "Add Organization" : "Save" }}
          </v-btn>
        </v-col>
      </v-row></v-banner
    >

    <v-row v-if="organization" class="mt-4">
      <v-col class="col-12 col-md-6">
        <v-card-title>
          <span>{{ mode === "edit" ? "Edit Existing" : "Add New" }} Organization</span>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="panels.length === panelsCount"
            text
            color="primary"
            @click="handleTogglePanels('open')"
            >Open All</v-btn
          >
          <v-btn
            :disabled="panels.length <= 0"
            text
            color="primary"
            @click="handleTogglePanels('close')"
            >Close All</v-btn
          >
        </v-card-title>

        <v-form ref="form" v-model="valid">
          <v-expansion-panels hover multiple v-model="panels">
            <!-- Organization Panel -->
            <v-expansion-panel>
              <v-expansion-panel-header>Organization Details</v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <div class="d-inline-flex">
                  <v-switch
                    v-model="organization.active"
                    label="Active"
                    hint="Sets whether the organization is active or not."
                    persistent-hint
                    prepend-icon="mdi-lightbulb-on-outline"
                  ></v-switch>
                </div>

                <v-text-field
                  v-model="organization.name"
                  :rules="nameRules"
                  label="Organization Name"
                  outlined
                  clearable
                  type="text"
                  class="mb-2 pt-6"
                ></v-text-field>
                <v-text-field
                  v-model="organization.url"
                  :rules="[handleCheckUrl]"
                  label="Organization Website"
                  hint="Enter a valid URL"
                  persistent-hint
                  outlined
                  clearable
                  type="text"
                ></v-text-field>
                <!-- <v-row>
                  <v-col>
          
                    <v-text-field
                      v-if="organization.logo"
                      :value="organization.logo.key"
                      :disabled="true"
                      label="Company Logo"
                      class="mb-4"
                    ></v-text-field>
                    <v-file-input
                      v-else
                      v-model="organization.logo"
                      :rules="imageRules"
                      accept="image/png, image/jpeg, image/bmp, image/svg"
                      prepend-icon="mdi-camera"
                      outlined
                      label="Company Logo"
                    ></v-file-input>
                
                  </v-col>
                  <v-col>
                    <p>
                      Select image to upload. Max size is 5MB. Edit photos
                      before upload with max dimensions of 600px width (this
                      will likely change and an image editor will be added to
                      ui).
                    </p>
                  </v-col>
                </v-row> -->
                <!-- Company Address -->
                <v-card-subtitle class="px-0">
                  <span>Organization Address</span>
                </v-card-subtitle>

                <v-text-field
                  v-model="organization.address.streetAddress1"
                  label="Street Address 1"
                  outlined
                  clearable
                  type="text"
                ></v-text-field>
                <v-text-field
                  v-model="organization.address.streetAddress2"
                  label="Street Address 2"
                  outlined
                  clearable
                  type="text"
                ></v-text-field>

                <v-text-field
                  v-model="organization.address.city"
                  label="City"
                  outlined
                  clearable
                  type="text"
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-select
                      v-model="organization.address.state"
                      :items="states"
                      item-text="name"
                      item-value="abbreviation"
                      :menu-props="{ maxHeight: '400' }"
                      label="State"
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="organization.address.postalCode"
                      label="Postal Code"
                      outlined
                      clearable
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-col>
      <v-col class="col-12 col-md-6">
        <div class="px-3">
          <div>
            <v-card
              v-if="
                organization &&
                organization.advisors &&
                organization.advisors.items.length > 0
              "
              outlined
              class="mx-auto"
            >
              <v-card-title class="grey lighten-5">
                Organization's Advisors
                <v-chip class="ml-3">
                  {{ organization.advisors.items.length }}
                </v-chip>
              </v-card-title>

              <!-- <v-card-text class="pt-4">
                <strong>{{
                  prospectSurveyCompleteCount(organization.prospects.items)
                }}</strong>
                of
                <strong>{{ organization.prospects.items.length }}</strong>
                prospects have completed the survey.
              </v-card-text> -->

              <v-divider></v-divider>

              <v-virtual-scroll
                :items="organization.advisors.items"
                :item-height="45"
                height="250"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ item.lastName }}, {{ item.firstName }} -
                        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        <br />
                        <small>{{ item.id }}</small>
                      </v-list-item-title>
                    </v-list-item-content>

                    <!-- <v-list-item-action v-if="item.scoreSurveyComplete">
                      <v-btn
                        depressed
                        small
                        @click="handleCopyReportLink(item.id)"
                      >
                        Copy
                        <v-icon color="primary darken-4" right>
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </v-list-item-action> -->

                    <!-- <v-list-item-action v-else> -- </v-list-item-action> -->

                    <!-- <v-list-item-action>
                      <v-btn
                        depressed
                        small
                        :to="handleOpenReportLink(item.id)"
                        target="_blank"
                      >
                        View
                        <v-icon color="primary darken-4" right>
                          mdi-open-in-new
                        </v-icon>
                      </v-btn>
                    </v-list-item-action> -->
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-card>
            <div v-else class="pa-3 text-center">Organization has no advisors.</div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-overlay :value="showOverlay" color="#091437" z-index="10000">
      <v-progress-circular indeterminate size="70"></v-progress-circular>
    </v-overlay>

    <v-dialog v-if="dialog" persistent v-model="dialog" max-width="800px">
      <v-card tile>
        <v-card-title class="mb-6 text-h6 white--text" :class="dialogStatus">{{
          dialogTitle
        }}</v-card-title>
        <v-card-text v-html="dialogText"></v-card-text>
        <v-divider></v-divider>
        <v-list v-if="showDialogActions" flat>
          <v-subheader>What's next?</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="action in dialogActions"
              :key="action.id"
              @click="handleDialogAction(action.type, action.action)"
            >
              <v-list-item-icon>
                <v-icon v-text="action.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="action.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions v-if="showDialogButtons" class="justify-end">
          <v-btn text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
  </div>
</template>

<script>
import { API, graphqlOperation, Storage } from "aws-amplify";
import awsExports from "@/aws-exports";

import { updateOrganization } from "@/graphql/mutations";
import { customGetOrganization } from "@/customGraphQL/customGetOrganization";
import { customCreateOrganization } from "@/customGraphQL/customCreateOrganization";
import states from "@/assets/states.json";
import { validatePhone } from "@/services/validatePhoneNumber";

const initialOrganization = function () {
  return {
    active: null,
    name: null,
    url: null,
    logo: null,
    phone: null,
    address: {
      streetAddress1: null,
      streetAddress2: null,
      city: null,
      state: null,
      postalCode: null,
    },
  };
};

export default {
  components: {},
  data() {
    return {
      states: states,
      panels: [0],
      panelsCount: 5, //number of expansion panels
      showOverlay: false,
      showSnackBar: false,
      snackBarColor: "info",
      snackBarText: "",
      dialog: false,
      dialogTitle: "",
      dialogText: "",
      showDialogActions: true,
      showDialogButtons: false,
      dialogStatus: "info",
      dialogActions: [
        {
          id: 1,
          text: "Back to organization management",
          icon: "mdi-arrow-left",
          type: "back",
          action: "/admin/organizations",
        },
        {
          id: 2,
          text: "Edit organization",
          icon: "mdi-pencil",
          type: "edit",
          action: "AdminOrganizationEdit",
        },
        // {
        //   id: 3,
        //   text: "Copy organization URL",
        //   icon: "mdi-content-copy",
        //   type: "copy",
        //   action: "",
        // },
        {
          id: 4,
          text: "Add new organization",
          icon: "mdi-account-plus",
          type: "reset",
          action: "/admin/organization/add",
        },
      ],
      step: 1,
      mode: null,
      valid: false,
      organization: null,
      urlRules: [
        (v) =>
          !v ||
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm.test(
            v
          ) ||
          // /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
          //   v)
          "Must be a valid URL and begin with http:// or https://",
      ],

      imageRules: [
        (v) => !v || v.size < 5000000 || "Image size should be less than 5 MB!",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 2) || "First name must be at least two letters",
      ],
      zipCodeRules: [
        (v) =>
          !v ||
          /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) ||
          "Please enter a valid postal code.",
      ],
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Organization" : "Edit Organization";
    },
  },

  created() {
    const organizationId = this.$route.params.id;
    const routeName = this.$route.name;
    this.checkRouteParam(organizationId, routeName);
  },
  mounted() {},
  methods: {
    async checkRouteParam(id, routeName) {
      if (id && routeName === "AdminOrganizationEdit") {
        console.log("routeName", id, routeName);
        this.organization = await this.fetchOrganization(id);
        console.log("THIS ORG ", this.organization);
        this.mode = "edit";
      } else {
        if (routeName !== "AdminOrganizationAdd") {
          this.$router.replace({ path: "/admin/organization/add" });
        }
        this.mode = "new";
        this.organization = new initialOrganization();
      }
    },
    handleCheckUrl(e) {
      let urlPattern = new RegExp(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim
        // /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      );

      let string = e;

      if (!string) {
        return true;
      }

      if (urlPattern.test(string)) {
        string = string.replace("https://", "").replace("http://", "");
        string = `https://${string}`;

        this.organization.url = string;
        return true;
      } else {
        return "Please enter a valid url.";
      }
    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      const targetEndYear = currentYear - 26;
      let firstYear = currentYear - 80;
      let years = [];
      for (var i = firstYear; i <= targetEndYear; i++) {
        years.push(firstYear++);
      }
      return years;
    },
    async fetchOrganization(id) {
      try {
        const response = await API.graphql(
          graphqlOperation(customGetOrganization, { id: id })
        );
        const organization = response.data.getOrganization;

        if (organization) {
          if (!organization.address) {
            organization.address = {
              streetAddress1: null,
              streetAddress2: null,
              city: null,
              state: null,
              postalCode: null,
            };
          }
        }
        return organization;
      } catch (error) {
        const message = `<p>There has been a problem attempting to look up the organization.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogActions = false;
        this.showDialogButtons = false;
        console.log("Error fetching organization", error);
      }
    },

    async handleCreateOrganization() {
      this.showOverlay = true;
      const organizationParams = Object.assign({}, this.organization);

      if (!organizationParams.name) {
        return;
      }
      if (organizationParams.phone) {
        const isValid = await this.handleValidatePhone(organizationParams.phone);

        if (!isValid) {
          return;
        }
      }

      // Upload Company Logo to S3
      try {
        if (organizationParams.logo) {
          const companyLogoFile = organizationParams.logo;
          const companyLogoS3Key = await Storage.put(
            companyLogoFile.name,
            companyLogoFile,
            {
              contentType: "image/*",
            }
          );
          organizationParams.logo = {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: companyLogoS3Key.key,
          };
        }
      } catch (error) {
        console.log("Error uploading company logo file: ", error);
        const message = `<p>There has been a problem attempting to upload images.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogButtons = false;
        this.showDialogActions = false;
      }
      // Upload Organization Avatar to S3
      try {
        if (organizationParams.logo) {
          const logoFile = organizationParams.logo;
          const logoS3Key = await Storage.put(logoFile.name, logoFile, {
            contentType: "image/*",
          });
          organizationParams.avatar = {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: logoS3Key.key,
          };
        }
      } catch (error) {
        console.log("Error uploading avatar file: ", error);
        const message = `<p>There has been a problem attempting to images.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogButtons = false;
        this.showDialogActions = false;
      }

      try {
        console.log("About to createOrganization. params are...", organizationParams);
        /// Add to DB
        const response = await API.graphql(
          graphqlOperation(customCreateOrganization, {
            input: organizationParams,
          })
        );

        // New organization
        let createdOrganization = response.data.createOrganization;

        // Fetch updated organization that now has vanity names obj
        this.organization = createdOrganization;

        this.dialogTitle = "Success!";
        this.dialogStatus = "success";
        this.dialogText = `Organization <strong>${this.organization.name}</strong> has been created!`;
        this.dialog = true;
        this.showDialogActions = true;
      } catch (error) {
        console.log("Error creating organization ", error);
        const message = `<p>There has been a problem attempting to create organization.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = message;
        this.dialog = true;
        this.showDialogActions = false;
      }
      this.showOverlay = false;
    },

    async handleValidatePhone(num) {
      try {
        // validate phone number
        const response = await validatePhone(num);

        if (response.name === "Error") {
          throw new Error(response.message);
        } else {
          return true;
        }
      } catch (errorMsg) {
        console.log("validate phone error", errorMsg);

        this.dialogTitle = "Error!";
        this.dialogStatus = "error";
        this.dialogText = `
              <p><strong>${errorMsg}</strong></p>
              <p class="error--text"><strong>${num}</strong></p>
              <p>Please enter a valid US phone number for the organization.</p>`;
        this.dialog = true;
        this.showDialogActions = false;
        this.showDialogButtons = true;
        this.showOverlay = false;
        return false;
      }
    },
    async handleUpdateOrganization() {
      this.showOverlay = true;
      const organizationParams = Object.assign({}, this.organization);
      if (organizationParams) {
        if (organizationParams.phone) {
          const isValid = await this.handleValidatePhone(organizationParams.phone);

          if (!isValid) {
            return;
          }
        }

        // Check if it is a file with name property
        // Upload image if so
        // Temporary - if not, don't upload anything b/c it is empty
        // or there is already an image in S3
        if (organizationParams.logo && organizationParams.logo.name) {
          const companyLogoFile = organizationParams.logo;
          try {
            const companyLogoS3Key = await Storage.put(
              companyLogoFile.name,
              companyLogoFile,
              {
                contentType: "image/*",
              }
            );
            organizationParams.logo = {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: companyLogoS3Key.key,
            };
          } catch (error) {
            console.log("Error uploading company logo file: ", error);
          }
        }
        if (organizationParams.logo && organizationParams.logo.name) {
          const logoFile = organizationParams.logo;
          try {
            const logoFileS3Key = await Storage.put(logoFile.name, logoFile, {
              contentType: "image/*",
            });
            organizationParams.avatar = {
              bucket: awsExports.aws_user_files_s3_bucket,
              region: awsExports.aws_user_files_s3_bucket_region,
              key: logoFileS3Key.key,
            };
          } catch (error) {
            console.log("Error uploading company logo file: ", error);
          }
        }

        // Do not send Advisors object
        delete organizationParams.advisors;
        // Do not send Promos object
        delete organizationParams.promos;
        delete organizationParams.resources;
        delete organizationParams.createdAt;
        delete organizationParams.updatedAt;
        delete organizationParams.__typename;
        delete organizationParams.address.__typename;

        console.log("ORG PARAMS TO SAVE", organizationParams);

        try {
          const response = await API.graphql(
            graphqlOperation(updateOrganization, { input: organizationParams })
          );
          const updatedOrganization = response.data.updateOrganization;

          Object.assign(this.organization, updatedOrganization);

          this.dialogTitle = "Success!";
          this.dialogStatus = "success";
          this.dialogText = `Organization <strong>${this.organization.name}</strong> has been updated!`;
          this.dialog = true;
          this.showDialogActions = true;
        } catch (error) {
          console.log("Error updating Organization...", error.errors[0].message);
          const message = `<p>There has been a problem attempting to update the organization.</p>
          <p><strong>${error.errors[0].message}</strong></p>
          <p>Please refresh and try again.</p>`;
          this.dialogTitle = "Error!";
          this.dialogStatus = "error";
          this.dialogText = message;
          this.dialog = true;
          this.showDialogActions = false;
        }
      }
      this.showOverlay = false;
    },

    handleSave() {
      switch (this.mode) {
        case "new":
          this.handleCreateOrganization();
          break;
        case "edit":
          this.handleUpdateOrganization();
          break;
      }
    },
    handleDialogAction(type, action) {
      const organizationId = this.organization.id;
      switch (type) {
        case "back":
          this.$router.push(action);
          break;
        // case "copy":
        //   this.handleCopyVanityLink(
        //     this.organization.vanityNames.items[0].vanityName
        //   );
        //   break;
        case "edit":
          if (this.mode === "edit") {
            this.dialog = false;
          } else {
            this.$router.push({
              name: action,
              params: { id: organizationId },
              replace: true,
            });
          }

          break;
        case "reset":
          if (this.mode === "new") {
            this.$router.go();
          } else {
            this.$router.push(action);
          }
          break;
        default:
          break;
      }
    },

    handleSnackbar({ isShowing, color, text }) {
      this.showSnackBar = isShowing;
      this.snackBarColor = color;
      this.snackBarText = text;
    },

    handleTogglePanels(dir) {
      if (dir === "close") {
        this.panels = [];
      } else {
        this.panels = [...Array(this.panelsCount).keys()].map((k, i) => i);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel-header {
  border: 1px solid lightgray;
  border-bottom: none;

  &--active {
    background-color: var(--v-primary-base);
    color: white;
  }
}

.v-expansion-panel-content {
  border: 1px solid var(--v-primary-base);
}
</style>
<style lang="scss">
.theme--light.v-expansion-panels .v-expansion-panel-header--active {
  .v-expansion-panel-header__icon .v-icon,
  .error--text {
    color: white !important;
  }
}

.v-application--is-ltr .rs-admin-edit .v-banner__wrapper {
  padding: 1rem;
}
</style>
