export const customGetOrganization = /* GraphQL */ `
  query CustomGetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      active
      logo {
        bucket
        region
        key
      }
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
      }
      phone
      advisors {
        items {
          id
          email
          firstName
          lastName
        }
      }
      promos {
        items {
          organizationId
          promoCode
          createdAt
          updatedAt
          description
          active
          duration
          endDate
          product_id
          discount
        }
      }
    }
  }
`;
