export const customCreateOrganization = /* GraphQL */ `
  mutation CustomCreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      active
      logo {
        bucket
        region
        key
      }
      url
      address {
        streetAddress1
        streetAddress2
        city
        state
        postalCode
      }
      phone
    }
  }
`;
